<template>
  <v-app>
    <v-main>
      <template v-if="!isLoading">
        <template v-if="browserSupported">
          <div class="home" :style="'height: ' + (isMobile ? (step === 2 ? '1030px' : '1000px') : step === 3 ? '100%' : '2250px') + ' !important'">
            <div class="bg"></div>
            <div class="bg-shape"></div>
            <div class="content">
              <div class="header">
                <a href="https://www.twitch.tv" target="_blank"><img class="twitch-logo-header" src="/imgs/twitch-logo.png" /></a>
                <template v-if="!isMobile">
                  <div class="welcome">{{ language === '日本語' ? campaign?.name + ' ' + $vuetify.lang.t('$vuetify.welcome_to') : $vuetify.lang.t('$vuetify.welcome_to') + ' ' + campaign?.name }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ language }}</div>
                </template>
                <template v-else>
                  <div class="welcome">{{ language === '日本語' ? campaign?.name + ' ' + $vuetify.lang.t('$vuetify.welcome_to') : $vuetify.lang.t('$vuetify.welcome_to') + ' ' + campaign?.name }}&nbsp;&nbsp;&nbsp;{{ language }}</div>
                </template>
                <div>
                  <div>
                    <v-combobox :items="languages" v-model="language" class="language-select" solo prepend-inner-icon="mdi-web" append-icon="" color="black" disabled></v-combobox>
                  </div>
                </div>
              </div>
              <div v-if="step === 1">
                <div class="step-1">
                  <div class="campaign-name">{{ campaign?.name }}</div>
                  <div class="campaign-title">{{ $vuetify.lang.t('$vuetify.landing_title') }}</div>
                  <div class="campaign-subtitle">{{ $vuetify.lang.t('$vuetify.landing_subtitle') }}</div>
                </div>
                <div class="bg-shape-2"></div>
                <div class="form">
                  <div class="rectangle-12"></div>
                  <div class="username">
                    <div class="twitch-id">Twitch ID *</div>
                    <div class="rectangle-306">
                      <input type="text" class="text-field" v-model="twitchId" disabled />
                    </div>
                    <div class="radio-buttons3">
                      <div class="frame-53">
                        <div class="affiliate">{{ $vuetify.lang.t('$vuetify.landing_affiliate') }}</div>
                      </div>
                      <div class="ellipse-53" :style="broadcasterType === 'affiliate' ? 'border-color: #beff00 !important;' : 'border-color: #353535 !important;'"></div>
                      <div class="ellipse-61" :style="broadcasterType === 'affiliate' ? 'background: #beff00 !important;' : 'background: #222222 !important;'"></div>
                    </div>
                    <div class="radio-buttons4">
                      <div class="frame-54">
                        <div class="type-partner">{{ $vuetify.lang.t('$vuetify.landing_partner') }}</div>
                      </div>
                      <div class="ellipse-54" :style="broadcasterType === 'partner' ? 'border-color: #beff00 !important;' : 'border-color: #353535 !important;'"></div>
                      <div class="ellipse-62" :style="broadcasterType === 'partner' ? 'background: #beff00 !important;' : 'background: #222222 !important;'"></div>
                    </div>
                  </div>
                  <div class="full-name">
                    <div class="full-name2">{{ $vuetify.lang.t('$vuetify.landing_full_name') }} *</div>
                    <div class="rectangle-31">
                      <input type="text" class="text-field" v-model="fullName" />
                    </div>
                  </div>
                  <div class="email">
                    <div class="email-address">{{ $vuetify.lang.t('$vuetify.landing_email') }} *</div>
                    <div class="rectangle-30">
                      <input type="text" class="text-field" v-model="emailAddress" />
                    </div>
                  </div>
                  <div class="contact">
                    <div class="contact-number">{{ $vuetify.lang.t('$vuetify.landing_contact') }} *</div>
                    <div class="rectangle-32"><input type="text" class="text-field" v-model="contactNumber" /></div>
                  </div>
                  <div class="email">
                    <div class="country">{{ $vuetify.lang.t('$vuetify.landing_country') }} *</div>
                    <div class="rectangle-305"><input type="text" class="text-field" v-model="country" /></div>
                  </div>
                  <div class="address">
                    <div class="address2">{{ $vuetify.lang.t('$vuetify.landing_address') }} *</div>
                    <div class="this-is-for-you-to-receive-physical-goods-if-you-are-going-to-move-house-in-the-following-three-months-please-put-your-current-shipping-address-and-mark-plan-to-move-house-here">
                      {{ $vuetify.lang.t('$vuetify.landing_address_info') }}
                    </div>
                    <div class="rectangle-33"><input type="text" class="text-field" v-model="address" /></div>
                  </div>
                  <div class="email">
                    <div class="state">{{ $vuetify.lang.t('$vuetify.landing_state') }} *</div>
                    <div class="rectangle-304"><input type="text" class="text-field" v-model="state" /></div>
                  </div>
                  <div class="email">
                    <div class="city">{{ $vuetify.lang.t('$vuetify.landing_city') }} *</div>
                    <div class="rectangle-303"><input type="text" class="text-field" v-model="city" /></div>
                  </div>
                  <div class="email">
                    <div class="zip-code">{{ zipCode() }} *</div>
                    <div class="rectangle-302"><input type="text" class="text-field" v-model="postalCode" /></div>
                  </div>
                  <div class="postcode">
                    <div class="group-5">
                      <div class="for-marketing-purpose-we-might-feature-you-in-our-communication-channels-do-you-agree-to-authorize-twitch-to-use-your-likeness-videos-clips-or-stream-contents-on-advertising-promotion">
                        {{ $vuetify.lang.t('$vuetify.landing_marketing') }}
                      </div>
                      <div class="attention-if-you-violate-the-community-guideline-t-s-or-local-law-etc-twitch-has-right-to-remove-any-promotional-resources">{{ $vuetify.lang.t('$vuetify.landing_attention') }}</div>
                      <div class="radio-buttons2">
                        <div class="frame-52">
                          <div class="i-agree-to-authorize">{{ $vuetify.lang.t('$vuetify.landing_agree') }}</div>
                        </div>
                        <div class="ellipse-52" :style="agree === 'Y' ? 'border-color: #beff00 !important;' : 'border-color: #353535 !important;'" @click="agree = 'Y'"></div>
                        <div class="ellipse-6" :style="agree === 'Y' ? 'background: #beff00 !important;' : 'background: #222222 !important;'" @click="agree = 'Y'"></div>
                      </div>
                      <div class="radio-buttons">
                        <div class="frame-5">
                          <div class="i-disagree-to-authorize">{{ $vuetify.lang.t('$vuetify.landing_disagree') }}</div>
                        </div>
                        <div class="ellipse-51" :style="agree === 'N' ? 'border-color: #beff00 !important;' : 'border-color: #353535 !important;'" @click="agree = 'N'"></div>
                        <div class="ellipse-5" :style="agree === 'N' ? 'background: #beff00 !important;' : 'background: #222222 !important;'" @click="agree = 'N'"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <sign-up-form-mobile
                  :screenWidth="screenWidth"
                  :twitchId="twitchId"
                  :broadcasterType="broadcasterType"
                  :campaignCountry="campaign.country"
                  @fullName="handleFullName"
                  @emailAddress="handleEmailAddress"
                  @contactNumber="handleContactNumber"
                  @country="handleCountry"
                  @address="handleAddress"
                  @userState="handleState"
                  @city="handleCity"
                  @zipCode="handleZipCode"
                  @agree="handleAgree"
                ></sign-up-form-mobile>
                <template v-if="valid === true">
                  <template v-if="multipleChoices.length > 0">
                    <div class="buttons" @click="next">
                      <div class="tab-name">{{ $vuetify.lang.t('$vuetify.landing_submit') }}</div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="buttons" @click="submit">
                      <div class="tab-name">{{ $vuetify.lang.t('$vuetify.landing_submit') }}</div>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <div class="buttons-disabled">
                    <div class="tab-name">{{ $vuetify.lang.t('$vuetify.landing_submit') }}</div>
                  </div>
                </template>
              </div>
              <div v-if="step === 2">
                <div class="step-2">
                  <div class="campaign-name">{{ campaign?.name }}</div>
                  <div class="prize-info">{{ campaign.landing_multiple_choices_title }}</div>
                </div>
                <div class="bg-shape-2"></div>
                <sign-up-form-prizes :multipleChoices="multipleChoices" :answers="answers" @answer="handleAnswer"></sign-up-form-prizes>
                <sign-up-form-prizes-mobile :multipleChoices="multipleChoices" :answers="answers" @answer="handleAnswer" :screenWidth="screenWidth"></sign-up-form-prizes-mobile>
                <template v-if="validPrizes === true">
                  <div class="buttons2" @click="submit">
                    <div class="tab-name">{{ $vuetify.lang.t('$vuetify.landing_submit') }}</div>
                  </div>
                </template>
                <template v-else>
                  <div class="buttons2-disabled">
                    <div class="tab-name">{{ $vuetify.lang.t('$vuetify.landing_submit') }}</div>
                  </div>
                </template>
              </div>
              <div v-if="step === 3">
                <div class="step-3">
                  <div class="main-title">{{ success ? $vuetify.lang.t('$vuetify.landing_success') : $vuetify.lang.t('$vuetify.landing_error') }}</div>
                  <div class="main-subtitle">
                    <template v-if="success">
                      <a href="https://glitch-exp.com" style="color: black; margin-right: 2px">
                        <b>{{ $vuetify.lang.t('$vuetify.landing_click_here') }}</b>
                      </a>
                      {{ ' ' }}
                      {{ $vuetify.lang.t('$vuetify.landing_success_info') }} {{ campaign?.name }}!
                    </template>
                    <template v-else>{{ $vuetify.lang.t('$vuetify.landing_error_info') }}</template>
                  </div>
                </div>
              </div>
              <div class="footer">
                <a href="https://www.twitch.tv" target="_blank"><img class="twitch-glitch-white" src="/imgs/twitch-glitch-white.png" /></a>
                <div>
                  <a class="terms-of-service" href="https://www.twitch.tv/p/en/legal/terms-of-service/" target="_blank">{{ $vuetify.lang.t('$vuetify.terms') }}</a>
                </div>
                <div class="partner">{{ $vuetify.lang.t('$vuetify.develop_by') }}</div>
                <div>
                  <a class="privacy-policy" href="https://www.twitch.tv/p/en/legal/privacy-notice/" target="_blank">{{ $vuetify.lang.t('$vuetify.privacy_policy') }}</a>
                </div>
                <div class="trade-mark">© {{ new Date().getFullYear() }} Twitch Interactive Inc</div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <unsupported />
        </template>
      </template>
      <template v-else>
        <v-overlay>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </template>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SignUpFormMobile from '../components/SignUpFormMobile.vue'
import SignUpFormPrizes from '../components/SignUpFormPrizes.vue'
import SignUpFormPrizesMobile from '../components/SignUpFormPrizesMobile.vue'
import Unsupported from '../components/Unsupported.vue'

import { isEmpty } from 'lodash'

export default {
  components: {
    SignUpFormMobile,
    SignUpFormPrizes,
    SignUpFormPrizesMobile,
    Unsupported
  },
  name: 'Home',
  data() {
    return {
      step: 0,
      success: false,
      campaign: null,
      screenHeight: 0,
      screenWidth: 0,
      browserSupported: true,
      isLoading: false,
      twitchId: null,
      broadcasterType: null,
      fullName: null,
      emailAddress: null,
      contactNumber: null,
      country: null,
      address: null,
      state: null,
      city: null,
      postalCode: null,
      agree: 'Y',
      multipleChoices: [],
      answers: [],
      countries: {
        ANZ: '',
        JP: '日本',
        TW: '台灣',
        HK: '',
        UK: 'United Kingdom',
        ES: 'España',
        FR: 'France',
        DE: 'Deutschland',
        BR: 'Brasil',
        MX: 'México',
        US: 'United States',
        CA: 'Canada'
      },
      language: 'English',
      languages: ['English', 'Português', 'Français', 'Español', 'Deutsch', '日本語', '中文 繁體'] // English, Portuguese, French, Spanish, German, Japanese, Chinese
    }
  },
  computed: {
    ...mapGetters(['user']),
    isMobile() {
      const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
      const isMobile = regex.test(navigator.userAgent)
      return isMobile
    },
    valid() {
      return !isEmpty(this.fullName) && !isEmpty(this.emailAddress) && !isEmpty(this.contactNumber) && !isEmpty(this.country) && !isEmpty(this.address) && !isEmpty(this.postalCode) && !isEmpty(this.state) && !isEmpty(this.city) && this.agree === 'Y'
    },
    validPrizes() {
      return this.answers.length === this.multipleChoices.length
    }
  },
  methods: {
    ...mapActions(['getSignUpCheck', 'submitSignUp']),
    onScreenResize() {
      window.addEventListener('resize', () => {
        this.updateScreenHeight()
        this.updateScreenWidth()
      })
    },
    updateScreenHeight() {
      this.screenHeight = window.innerHeight
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth
    },
    zipCode() {
      switch (this.campaign.country) {
        case 'ANZ':
          return 'Post Code'
        case 'JP':
          return '郵便番号'
        case 'TW':
          return '區碼'
        case 'HK':
          return '區碼'
        case 'UK':
          return 'Zip Code'
        case 'ES':
          return 'Código postal'
        case 'FR':
          return 'Code Postal'
        case 'DE':
          return 'PLZ'
        case 'BR':
          return 'CEP'
        case 'MX':
          return 'Código postal'
        case 'CA':
          return 'Postal Code'
        default:
          return 'ZIP Code'
      }
    },
    next() {
      this.step = 2
    },
    submit() {
      const campaignScore = this.campaign.scores[0]
      const choices = {}
      if (this.multipleChoices.length > 0 && this.answers.length > 0) {
        this.answers.forEach((a) => {
          choices[a.question] = a.answer
        })
      }
      const payload = {
        id: campaignScore.id,
        full_name: this.fullName,
        email: this.emailAddress,
        phone: this.contactNumber,
        country: this.country,
        address: this.address,
        state: this.state,
        city: this.city,
        zip_code: this.postalCode,
        authorize: this.agree === 'Y',
        multiple_choices: choices
      }
      this.isLoading = true
      this.submitSignUp(payload).then((result) => {
        this.isLoading = false
        if (result) {
          this.step = 3
          this.success = true
        } else {
          this.step = 3
          this.success = false
        }
      })
    },
    setLanguage() {
      const language = this.campaign.country
      let preferredLanguage
      if (['BR'].includes(language)) {
        preferredLanguage = 'pt'
        this.language = 'Português'
      } else if (['FR'].includes(language)) {
        preferredLanguage = 'fr'
        this.language = 'Français'
      } else if (['ES', 'MX'].includes(language)) {
        preferredLanguage = 'es'
        this.language = 'Español'
      } else if (['DE'].includes(language)) {
        preferredLanguage = 'de'
        this.language = 'Deutsch'
      } else if (['JP'].includes(language)) {
        preferredLanguage = 'ja'
        this.language = '日本語'
      } else if (['TW', 'HK'].includes(language)) {
        preferredLanguage = 'zh'
        this.language = '中文 繁體'
      } else {
        preferredLanguage = 'en'
        this.language = 'English'
      }
      this.$vuetify.lang.current = preferredLanguage
    },
    dictionaryToArray(multipleChoices) {
      var array = []
      for (const key in multipleChoices) {
        const temp = {
          question: key,
          choices: multipleChoices[key]
        }
        array.push(temp)
      }
      return array
    },
    populateForm() {
      this.twitchId = this.user?.login
      this.broadcasterType = this.user?.broadcaster_type ?? null
      this.country = this.countries[this.campaign.country]
      this.multipleChoices = this.dictionaryToArray(this.campaign.multiple_choices ?? {})
    },
    handleAnswer(answer) {
      const found = this.answers.find((a) => a.question === answer.question)
      if (found) {
        found.answer = answer.answer
      } else {
        this.answers.push(answer)
      }
    },
    handleFullName(fullName) {
      this.fullName = fullName
    },
    handleEmailAddress(emailAddress) {
      this.emailAddress = emailAddress
    },
    handleContactNumber(contactNumber) {
      this.contactNumber = contactNumber
    },
    handleCountry(country) {
      this.country = country
    },
    handleAddress(address) {
      this.address = address
    },
    handleState(state) {
      this.state = state
    },
    handleCity(city) {
      this.city = city
    },
    handleZipCode(postalCode) {
      this.postalCode = postalCode
    },
    handleAgree(agree) {
      this.agree = agree
    }
  },
  mounted() {
    this.updateScreenHeight()
    this.updateScreenWidth()
    this.onScreenResize()
    if (process.env.VUE_APP_NO_PROD_REDIRECT !== 'true') {
      location.replace('https://glitch-exp.com')
    }
    if (sessionStorage.campaign && sessionStorage.user) {
      this.campaign = JSON.parse(sessionStorage.campaign)
      this.isLoading = true
      this.getSignUpCheck(this.campaign.slug).then((result) => {
        if (result) {
          this.campaign = result
          const score = this.campaign.scores[0]
          if (score.submitted) {
            this.step = 3
            this.success = true
          } else {
            this.step = 1
          }
        } else {
          this.step = 3
          this.success = false
        }
        this.populateForm()
        this.setLanguage()
        this.isLoading = false
      })
    } else {
      this.$router.push({
        name: 'home'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
body {
  background: #000000 !important;
}

.home,
.home * {
  box-sizing: border-box;
}

.home {
  background: #000000 !important;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  @media only screen and (max-height: 900px) {
    height: 950px;
  }
}

.bg {
  background: linear-gradient(180deg, rgba(145, 70, 255, 1) 0%, rgba(234, 112, 120, 1) 75.02173781394958%);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
}

.bg-shape {
  background: #000000;
  width: 100%;
  height: 350px;
  position: absolute;
  left: 0px;
  bottom: 0px;

  @media only screen and (max-height: 900px) {
    height: 170px;
  }
}

.content {
  margin: auto;
  padding: 12px;
  min-width: 1420px;
  max-width: 1920px;

  @media only screen and (max-height: 900px) {
    max-height: 900px;
  }
}

.welcome {
  z-index: 1000;
  color: #000000;

  font-family: 'RoobertRegular';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
  /* or 90% */
  letter-spacing: -0.005em;

  position: absolute;
  top: 70px;
  right: 160px;
  width: 620px;
  height: 38.59px;
  display: flex;
  align-items: center;
  justify-content: right;
  cursor: pointer;

  @media only screen and (max-height: 900px) {
    font-size: 12px;
    top: 50px;
    right: -150px;
    justify-content: center;
  }
}

.language-select {
  border-radius: 20px;
  cursor: pointer;
  z-index: 100;
  font: 600 20px 'RoobertRegular', sans-serif;
  position: absolute;
  top: 65px;
  right: 40px;
  width: 115px;

  @media only screen and (max-height: 900px) {
    font-size: 12px;
    top: 46px;
    right: 4px;
    width: 48px;
    z-index: 1001;
  }
}

.twitch-logo-header {
  z-index: 1;
  width: 131px;
  height: 44px;
  position: absolute;
  left: 100px;
  top: 60px;

  @media only screen and (max-height: 900px) {
    width: 54px;
    height: 18px;
    left: 18px;
  }
}

.main-title {
  color: #000000;
  text-align: left;
  font: 600 45px/125% 'RoobertRegular', sans-serif;
  position: absolute;
  top: calc(50% - 220px);
  left: 0;
  margin: 0;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  @media only screen and (max-height: 900px) {
    font: 600 25px/125% 'RoobertRegular', sans-serif;
    top: calc(50% - 160px);
  }
}

.main-subtitle {
  z-index: 1;
  margin: 0;
  left: 0;
  height: 100px;
  align-items: center;
  justify-content: center;
  z-index: 10;
  top: calc(50% - 100px);
  width: 100%;
  position: absolute;
  color: #000000;
  text-align: center;
  font: 400 30px/125% 'RoobertRegular', sans-serif;
  padding-left: 250px;
  padding-right: 250px;

  @media only screen and (max-height: 900px) {
    font: 400 15px/125% 'RoobertRegular', sans-serif;
    height: 150px;
    padding-left: 50px;
    padding-right: 50px;
    top: calc(50% - 90px);
  }
}

.footer {
  position: absolute;
  inset: 0;
}

.twitch-glitch-white {
  z-index: 1;
  width: 64px;
  height: 75px;
  position: absolute;
  left: 98px;
  bottom: 208px;

  @media only screen and (max-height: 900px) {
    width: 20px;
    height: 24px;
    left: 20px;
    bottom: 106px;
  }
}

.terms-of-service {
  z-index: 1;
  color: #ffffff;
  text-align: center;
  font: 500 20px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 100px;
  bottom: 89px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  @media only screen and (max-height: 900px) {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 37px;
    left: 20px;
    bottom: 53px;
  }
}
.partner {
  z-index: 1;
  color: #b3b3b3;
  text-align: right;
  font: 500 20px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  bottom: 89px;
  right: 98px;
  width: 984px;
  align-items: right;

  @media only screen and (max-height: 900px) {
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 14px;
    right: 20px;
    bottom: 25px;
    min-width: 200px;
    max-width: 220px;
  }
}

.privacy-policy {
  z-index: 1;
  color: #ffffff;
  text-align: center;
  font: 500 20px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 330px;
  bottom: 89px;
  display: flex;
  justify-content: left;
  text-decoration: none;

  @media only screen and (max-height: 900px) {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 37px;
    left: 20px;
    bottom: 29px;
  }
}

.trade-mark {
  z-index: 1;
  color: #ffffff;
  text-align: center;
  font: 500 20px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  bottom: 122px;
  right: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-height: 900px) {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 37px;
    right: 20px;
    bottom: 53px;
  }
}

.step-1 {
  color: #000000;
  text-align: left;
  position: absolute;
  top: 160px;
  left: calc(50% - 630px);
  margin: 0;
  width: 100%;
  height: 100px;
  display: flex;
  z-index: 10;
  flex-direction: column;
  @media only screen and (max-height: 900px) {
    top: 100px;
    left: 24px;
  }
}

.campaign-name {
  color: #000000;
  text-align: left;
  font: 700 100px/90% 'RoobertRegular', sans-serif;
  max-width: 1792px;
  height: 491px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media only screen and (max-height: 900px) {
    font: 700 40px/90% 'RoobertRegular', sans-serif;
  }
}

.campaign-title {
  color: #000000;
  text-align: left;
  font: 600 30px/90% 'RoobertRegular', sans-serif;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 40px;

  @media only screen and (max-height: 900px) {
    font: 600 15px/106% 'RoobertRegular', sans-serif;
    width: 345px;
  }
}

.campaign-subtitle {
  color: #000000;
  text-align: left;
  font: 400 30px/90% 'RoobertRegular', sans-serif;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 4px;

  @media only screen and (max-height: 900px) {
    font: 400 15px/106% 'RoobertRegular', sans-serif;
    width: 380px;
  }
}

.prize-info {
  color: #000000;
  text-align: left;
  font: 400 30px/90% 'RoobertRegular', sans-serif;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 40px;
  max-width: 1300px;

  @media only screen and (max-height: 900px) {
    font: 400 15px/106% 'RoobertRegular', sans-serif;
    width: 345px;
  }
}

.bg-shape-2 {
  background: #000000;
  border-radius: 100px 100px 0px 0px;
  height: 2300px;
  top: 390px;
  left: 0;
  margin: 0;
  width: 100%;
  z-index: 1;
  position: absolute;

  @media only screen and (max-height: 900px) {
    border-radius: 30px 30px 0px 0px;
    top: 250px;
    height: 1050px;
  }
}
.form {
  position: relative;
  margin-top: -480px;
  margin-bottom: 1330px;
  width: 1316px;
  height: 1316px;
  z-index: 1;
  margin-left: -95px;

  @media only screen and (max-width: 1440px) {
    margin-left: -250px;
  }

  @media only screen and (max-width: 1366px) {
    margin-left: -288px;
  }

  @media only screen and (max-height: 900px) {
    display: none;
  }
}
.rectangle-12 {
  background: #111111;
  border-radius: 40px;
  width: 1316px;
  height: 1316px;
  position: absolute;
  left: 301px;
  top: 919px;
}
.buttons {
  background: #beff00;
  border-radius: 326.53px;
  padding: 15px 32.8px 15px 32.8px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 100px);
  top: calc(50% + 470px);
  cursor: pointer;
  z-index: 100;

  @media only screen and (max-height: 900px) {
    left: calc(50% - 45px);
    top: calc(50% + 275px);
    padding: 0px 15px 0px 15px;
  }
}
.buttons:hover {
  background: #7d5bbe;
}
.buttons2 {
  background: #beff00;
  border-radius: 326.53px;
  padding: 15px 32.8px 15px 32.8px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 70px);
  top: calc(50% + 630px);
  z-index: 1;
  cursor: pointer;

  @media only screen and (max-height: 900px) {
    left: calc(50% - 45px);
    top: calc(50% + 260px);
    padding: 0px 15px 0px 15px;
  }
}
.buttons2:hover {
  background: #7d5bbe;
}
.buttons2-disabled {
  background: gray;
  border-radius: 326.53px;
  padding: 15px 32.8px 15px 32.8px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 70px);
  top: calc(50% + 630px);
  z-index: 1;
  cursor: pointer;

  @media only screen and (max-height: 900px) {
    left: calc(50% - 45px);
    top: calc(50% + 260px);
    padding: 0px 15px 0px 15px;
  }
}
.buttons2-disabled:hover {
  background: #353535;
}
.buttons-disabled {
  background: gray;
  border-radius: 326.53px;
  padding: 15px 32.8px 15px 32.8px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 100px);
  top: calc(50% + 470px);
  cursor: pointer;
  z-index: 100;

  @media only screen and (max-height: 900px) {
    left: calc(50% - 45px);
    top: calc(50% + 275px);
    padding: 0px 15px 0px 15px;
  }
}
.buttons-disabled:hover {
  background: #353535;
}
.tab-name {
  color: var(--black-ops, #000000);
  text-align: center;
  font: 600 34.21px/42.45px 'RoobertRegular', sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-height: 900px) {
    font: 600 16px/32.45px 'RoobertRegular', sans-serif;
  }
}
.postcode {
  width: 1161px;
  height: 197px;
  position: static;
}
.group-5 {
  width: 1161px;
  height: 197px;
  position: static;
}
.for-marketing-purpose-we-might-feature-you-in-our-communication-channels-do-you-agree-to-authorize-twitch-to-use-your-likeness-videos-clips-or-stream-contents-on-advertising-promotion {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 25px/110% 'RoobertRegular', sans-serif;
  position: absolute;
  left: 378px;
  top: 1750px;
  width: 1161px;
}
.attention-if-you-violate-the-community-guideline-t-s-or-local-law-etc-twitch-has-right-to-remove-any-promotional-resources {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 300 italic 18px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 378px;
  top: 1838px;
  width: 1094px;
}
.radio-buttons {
  width: 287px;
  height: 30px;
  position: static;
}
.frame-5 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 407px;
  top: 1937px;
}
.i-disagree-to-authorize {
  color: #f0f0ff;
  text-align: left;
  font: 500 25px/120% 'RoobertRegular', sans-serif;
  position: relative;
}
.ellipse-5 {
  background: #beff00;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  position: absolute;
  left: 381px;
  top: 1943px;
  cursor: pointer;
}
.radio-buttons2 {
  width: 254px;
  height: 30px;
  position: static;
}
.frame-52 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 407px;
  top: 1890px;
}
.i-agree-to-authorize {
  color: #f0f0ff;
  text-align: left;
  font: 500 25px/120% 'RoobertRegular', sans-serif;
  position: relative;
}
.ellipse-51 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #beff00;
  border-width: 1px;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 378px;
  top: 1940px;
}
.ellipse-52 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #beff00;
  border-width: 1px;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 378px;
  top: 1895px;
}
.ellipse-6 {
  background: #beff00;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  position: absolute;
  left: 381px;
  top: 1898px;
  cursor: pointer;
}
.contact {
  width: 508px;
  height: 85px;
  position: static;
}
.rectangle-32 {
  background: #222222;
  border-radius: 10px;
  border-style: solid;
  border-color: #353535;
  border-width: 2px;
  width: 508px;
  height: 50px;
  position: absolute;
  left: 972px;
  top: 1242px;
}
.contact-number {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 25px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 975px;
  top: 1207px;
  width: 500px;
}
.address {
  width: 524px;
  height: 169px;
  position: static;
}
.rectangle-33 {
  background: #222222;
  border-radius: 10px;
  border-style: solid;
  border-color: #353535;
  border-width: 2px;
  width: 508px;
  height: 50px;
  position: absolute;
  left: 966px;
  top: 1500px;
}
.address2 {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 25px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 966px;
  top: 1353px;
  width: 316px;
}
.this-is-for-you-to-receive-physical-goods-if-you-are-going-to-move-house-in-the-following-three-months-please-put-your-current-shipping-address-and-mark-plan-to-move-house-here {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 300 italic 18px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 966px;
  top: 1389px;
  width: 524px;
}
.full-name {
  width: 508px;
  height: 86px;
  position: static;
}
.rectangle-31 {
  background: #222222;
  border-radius: 10px;
  border-style: solid;
  border-color: #353535;
  border-width: 2px;
  width: 508px;
  height: 50px;
  position: absolute;
  left: 966px;
  top: 1053px;
}
.full-name2 {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 25px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 969px;
  top: 1017px;
  width: 500px;
}
.email {
  width: 508px;
  height: 89px;
  position: static;
}
.rectangle-30 {
  background: #222222;
  border-radius: 10px;
  border-style: solid;
  border-color: #353535;
  border-width: 2px;
  width: 508px;
  height: 50px;
  position: absolute;
  left: 379px;
  top: 1246px;
}
.email-address {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 25px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 382px;
  top: 1207px;
  width: 500px;
}
.rectangle-302 {
  background: #222222;
  border-radius: 10px;
  border-style: solid;
  border-color: #353535;
  border-width: 2px;
  width: 508px;
  height: 50px;
  position: absolute;
  left: 969px;
  top: 1626px;
}
.zip-code {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 25px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 972px;
  top: 1587px;
  width: 500px;
}
.rectangle-303 {
  background: #222222;
  border-radius: 10px;
  border-style: solid;
  border-color: #353535;
  border-width: 2px;
  width: 508px;
  height: 50px;
  position: absolute;
  left: 378px;
  top: 1627px;
}
.city {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 25px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 381px;
  top: 1588px;
  width: 500px;
}
.rectangle-304 {
  background: #222222;
  border-radius: 10px;
  border-style: solid;
  border-color: #353535;
  border-width: 2px;
  width: 508px;
  height: 50px;
  position: absolute;
  left: 379px;
  top: 1500px;
}
.state {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 25px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 382px;
  top: 1461px;
  width: 500px;
}
.rectangle-305 {
  background: #222222;
  border-radius: 10px;
  border-style: solid;
  border-color: #353535;
  border-width: 2px;
  width: 508px;
  height: 50px;
  position: absolute;
  left: 379px;
  top: 1373px;
}
.country {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 25px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 382px;
  top: 1334px;
  width: 500px;
}
.username {
  width: 508px;
  height: 141px;
  position: static;
}
.rectangle-306 {
  background: #222222;
  border-radius: 10px;
  border-style: solid;
  border-color: #353535;
  border-width: 2px;
  width: 508px;
  height: 50px;
  position: absolute;
  left: 379px;
  top: 1053px;
}
.twitch-id {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 25px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 382px;
  top: 1014px;
  width: 500px;
}
.radio-buttons3 {
  width: 114px;
  height: 30px;
  position: static;
}
.frame-53 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 416px;
  top: 1125px;
}
.affiliate {
  color: #f0f0ff;
  text-align: left;
  font: 500 25px/120% 'RoobertRegular', sans-serif;
  position: relative;
}
.ellipse-53 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #353535;
  border-width: 1px;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 390px;
  top: 1132px;
}
.radio-buttons4 {
  width: 110px;
  height: 30px;
  position: static;
}
.frame-54 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 549px;
  top: 1125px;
}
.type-partner {
  color: #f0f0ff;
  text-align: left;
  font: 500 25px/120% 'RoobertRegular', sans-serif;
  position: relative;
}
.ellipse-54 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #beff00;
  border-width: 1px;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 523px;
  top: 1131px;
}
.ellipse-62 {
  background: #beff00;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  position: absolute;
  left: 526px;
  top: 1134px;
}
.ellipse-61 {
  background: #beff00;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  position: absolute;
  left: 393px;
  top: 1135px;
}
.step-2 {
  color: #000000;
  text-align: left;
  position: absolute;
  top: 160px;
  left: calc(50% - 630px);
  margin: 0;
  width: 100%;
  height: 100px;
  display: flex;
  z-index: 10;
  flex-direction: column;
  @media only screen and (max-height: 900px) {
    top: 100px;
    left: 24px;
  }
}

.break {
  display: unset;
  @media only screen and (max-height: 900px) {
    display: none;
  }
}

.text-field {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  font: 500 26px/64.44px 'RoobertRegular', sans-serif;
  margin-left: 6px;
  margin-top: -10px;
  width: 492px;
  z-index: 100 !important;
}

.radio-field {
  position: absolute;
  z-index: 90 !important;
  margin-left: -1px;
  margin-top: -1px;
  border-width: 1px !important;
  width: 20px;
  height: 20px;
}
</style>
