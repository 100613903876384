<template>
  <div>
    <div class="exp-ui-test" :style="screenWidth > 390 ? 'margin-left: 5px;' : 'margin-left: -12px;'">
      <div class="rectangle-12"></div>
      <div class="username">
        <div class="twitch-id">Twitch ID *</div>
        <div class="rectangle-30">
          <input type="text" class="text-field" v-model="twitchId" disabled />
        </div>
        <div class="radio-buttons">
          <div class="frame-5">
            <div class="affiliate">{{ $vuetify.lang.t('$vuetify.landing_affiliate') }}</div>
          </div>
          <div class="ellipse-5" :style="broadcasterType === 'affiliate' ? 'border-color: #beff00 !important;' : 'border-color: #353535 !important;'"></div>
          <div class="ellipse-6A" :style="broadcasterType === 'affiliate' ? 'background: #beff00 !important;' : 'background: #222222 !important;'"></div>
        </div>
        <div class="radio-buttons2">
          <div class="frame-52">
            <div class="partner">{{ $vuetify.lang.t('$vuetify.landing_partner') }}</div>
          </div>
          <div class="ellipse-52" :style="broadcasterType === 'partner' ? 'border-color: #beff00 !important;' : 'border-color: #353535 !important;'"></div>
          <div class="ellipse-6" :style="broadcasterType === 'partner' ? 'background: #beff00 !important;' : 'background: #222222 !important;'"></div>
        </div>
      </div>
      <div class="full-name">
        <div class="full-name-text">{{ $vuetify.lang.t('$vuetify.landing_full_name') }} *</div>
        <div class="rectangle-fn">
          <input type="text" class="text-field" @blur="(event) => fullName(event.target.value)" />
        </div>
      </div>
      <div class="full-name">
        <div class="full-name2">{{ $vuetify.lang.t('$vuetify.landing_email') }} *</div>
        <div class="rectangle-31">
          <input type="text" class="text-field" @blur="(event) => emailAddress(event.target.value)" />
        </div>
      </div>
      <div class="email">
        <div class="zip-code">{{ $vuetify.lang.t('$vuetify.landing_contact') }} *</div>
        <div class="rectangle-332">
          <input type="text" class="text-field" @blur="(event) => contactNumber(event.target.value)" />
        </div>
      </div>
      <div class="email">
        <div class="email-address">{{ $vuetify.lang.t('$vuetify.landing_country') }} *</div>
        <div class="rectangle-33"><input type="text" class="text-field" v-model="country" @blur="(event) => onCountry(event.target.value)" /></div>
      </div>
      <div class="address">
        <div class="address2">{{ $vuetify.lang.t('$vuetify.landing_address') }} *</div>
        <div class="this-is-for-you-to-receive-physical-goods-if-you-are-going-to-move-house-in-the-following-three-months-please-put-your-current-shipping-address-and-mark-plan-to-move-house-here">
          {{ $vuetify.lang.t('$vuetify.landing_address_info') }}
        </div>
        <div class="rectangle-34">
          <input type="text" class="text-field" @blur="(event) => address(event.target.value)" />
        </div>
      </div>
      <div class="contact">
        <div class="contact-number">{{ $vuetify.lang.t('$vuetify.landing_state') }} *</div>
        <div class="rectangle-32">
          <input type="text" class="text-field" @blur="(event) => userState(event.target.value)" />
        </div>
      </div>
      <div class="contact">
        <div class="city">{{ $vuetify.lang.t('$vuetify.landing_city') }} *</div>
        <div class="rectangle-city">
          <input type="text" class="text-field" @blur="(event) => city(event.target.value)" />
        </div>
      </div>
      <div class="email">
        <div class="zip">{{ zipCodeLabel() }} *</div>
        <div class="rectangle-zip">
          <input type="text" class="text-field" @blur="(event) => zipCode(event.target.value)" />
        </div>
      </div>
    </div>
    <div class="postcode">
      <div class="group-5">
        <div class="marketing" :style="screenWidth > 390 ? 'left: 75px;' : 'left: 58px;'">
          {{ $vuetify.lang.t('$vuetify.landing_marketing') }}
        </div>
        <div class="attention" :style="screenWidth > 390 ? 'left: 75px;' : 'left: 58px;'">{{ $vuetify.lang.t('$vuetify.landing_attention') }}</div>
        <div class="radio-buttons4">
          <div class="frame-54">
            <div class="i-agree-to-authorize">{{ $vuetify.lang.t('$vuetify.landing_agree') }}</div>
          </div>
          <div class="ellipse-54" :style="agreed === 'Y' ? 'border-color: #beff00 !important;' : 'border-color: #353535 !important;'" @click="agree('Y')"></div>
          <div class="ellipse-62" :style="agreed === 'Y' ? 'background: #beff00 !important;' : 'background: #222222 !important;'" @click="agree('Y')"></div>
        </div>
        <div class="radio-buttons3">
          <div class="frame-53">
            <div class="i-disagree-to-authorize">{{ $vuetify.lang.t('$vuetify.landing_disagree') }}</div>
          </div>
          <div class="ellipse-53" :style="agreed === 'N' ? 'border-color: #beff00 !important;' : 'border-color: #353535 !important;'" @click="agree('N')"></div>
          <div class="ellipse-63" :style="agreed === 'N' ? 'background: #beff00 !important;' : 'background: #222222 !important;'" @click="agree('N')"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignUpFormMobile',
  props: {
    screenWidth: {
      type: Number,
      default() {
        return 0
      }
    },
    twitchId: {
      type: String,
      default() {
        return null
      }
    },
    broadcasterType: {
      type: String,
      default() {
        return null
      }
    },
    campaignCountry: {
      type: String,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      agreed: 'Y',
      country: null,
      countries: {
        ANZ: '',
        JP: '日本',
        TW: '台灣',
        HK: '',
        UK: 'United Kingdom',
        ES: 'España',
        FR: 'France',
        DE: 'Deutschland',
        BR: 'Brasil',
        MX: 'México',
        US: 'United States',
        CA: 'Canada'
      }
    }
  },
  methods: {
    zipCodeLabel() {
      switch (this.campaignCountry) {
        case 'ANZ':
          return 'Post Code'
        case 'JP':
          return '郵便番号'
        case 'TW':
          return '區碼'
        case 'HK':
          return '區碼'
        case 'UK':
          return 'Zip Code'
        case 'ES':
          return 'Código postal'
        case 'FR':
          return 'Code Postal'
        case 'DE':
          return 'PLZ'
        case 'BR':
          return 'CEP'
        case 'MX':
          return 'Código postal'
        case 'CA':
          return 'Postal Code'
        default:
          return 'ZIP Code'
      }
    },
    fullName(fullName) {
      this.$emit('fullName', fullName)
    },
    emailAddress(emailAddress) {
      this.$emit('emailAddress', emailAddress)
    },
    contactNumber(contactNumber) {
      this.$emit('contactNumber', contactNumber)
    },
    onCountry(country) {
      this.$emit('country', country)
    },
    address(address) {
      this.$emit('address', address)
    },
    userState(state) {
      this.$emit('userState', state)
    },
    city(city) {
      this.$emit('city', city)
    },
    zipCode(zipCode) {
      this.$emit('zipCode', zipCode)
    },
    agree(agree) {
      this.agreed = agree
      this.$emit('agree', agree)
    }
  },
  mounted() {
    this.country = this.countries[this.campaignCountry]
  }
}
</script>

<style lang="scss" scoped>
.exp-ui-test {
  inset: 0;
  display: none;

  @media only screen and (max-height: 900px) {
    display: unset;
    position: relative;
    top: -16px;
    margin-left: -12px;
    width: 307px;
    height: 476px;
    z-index: 1;
  }
}
.rectangle-12 {
  background: #111111;
  border-radius: 10px;
  width: 307px;
  height: 547px;
  position: absolute;
  left: 42px;
  top: 279px;
  z-index: 1;
}
.buttons {
  background: #beff00;
  border-radius: 326.53px;
  padding: 1px 10px 1px 10px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: center;
  height: 30px;
  position: absolute;
  left: 157px;
  top: 700px;
  z-index: 1;
}
.tab-name {
  color: var(--black-ops, #000000);
  text-align: center;
  font: 600 14px/42.45px 'RoobertRegular', sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.address {
  width: 125px;
  height: 83px;
  position: static;
  z-index: 1;
}
.rectangle-34 {
  background: #222222;
  border-radius: 5px;
  border-style: solid;
  border-color: #353535;
  border-width: 1px;
  width: 125px;
  height: 20px;
  position: absolute;
  left: 204px;
  top: 526px;
  display: flex;
  z-index: 1;
}
.address2 {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 13px/95% 'RoobertRegular', sans-serif;
  position: absolute;
  left: 204px;
  top: 456px;
  width: 96px;
  z-index: 1;
}
.this-is-for-you-to-receive-physical-goods-if-you-are-going-to-move-house-in-the-following-three-months-please-put-your-current-shipping-address-and-mark-plan-to-move-house-here {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 300 italic 7px/95% 'RoobertRegular', sans-serif;
  position: absolute;
  left: 204px;
  top: 476px;
  width: 125px;
  z-index: 1;
}
.email {
  width: 125px;
  height: 44px;
  position: static;
  z-index: 1;
}
.rectangle-33 {
  background: #222222;
  border-radius: 5px;
  border-style: solid;
  border-color: #353535;
  border-width: 1px;
  width: 125px;
  height: 20px;
  position: absolute;
  left: 58px;
  top: 465px;
  display: flex;
  z-index: 1;
}
.email-address {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 13px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 58px;
  top: 441px;
  width: 114px;
  z-index: 1;
}
.rectangle-332 {
  background: #222222;
  border-radius: 5px;
  border-style: solid;
  border-color: #353535;
  border-width: 1px;
  width: 125px;
  height: 20px;
  position: absolute;
  left: 204px;
  top: 404px;
  display: flex;
  z-index: 1;
}
.rectangle-zip {
  background: #222222;
  border-radius: 5px;
  border-style: solid;
  border-color: #353535;
  border-width: 1px;
  width: 125px;
  height: 20px;
  position: absolute;
  left: 204px;
  top: 586px;
  display: flex;
  z-index: 1;
}
.zip-code {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 13px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 204px;
  top: 380px;
  width: 114px;
  z-index: 1;
}
.zip {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 13px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 204px;
  top: 562px;
  width: 114px;
  z-index: 1;
}
.contact {
  width: 141px;
  height: 44px;
  position: static;
  z-index: 1;
}
.rectangle-32 {
  background: #222222;
  border-radius: 5px;
  border-style: solid;
  border-color: #353535;
  border-width: 1px;
  width: 125px;
  height: 20px;
  position: absolute;
  left: 57px;
  top: 526px;
  display: flex;
  z-index: 1;
}
.rectangle-city {
  background: #222222;
  border-radius: 5px;
  border-style: solid;
  border-color: #353535;
  border-width: 1px;
  width: 125px;
  height: 20px;
  position: absolute;
  left: 57px;
  top: 586px;
  display: flex;
  z-index: 1;
}
.contact-number {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 13px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 57px;
  top: 502px;
  width: 141px;
  z-index: 1;
}
.city {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 13px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 57px;
  top: 562px;
  width: 141px;
  z-index: 1;
}
.full-name {
  width: 141px;
  height: 44px;
  position: static;
  z-index: 1;
}
.rectangle-fn {
  background: #222222;
  border-radius: 5px;
  border-style: solid;
  border-color: #353535;
  border-width: 1px;
  width: 125px;
  height: 20px;
  position: absolute;
  left: 204px;
  top: 321px;
  display: flex;
  z-index: 1;
}
.rectangle-31 {
  background: #222222;
  border-radius: 5px;
  border-style: solid;
  border-color: #353535;
  border-width: 1px;
  width: 125px;
  height: 20px;
  position: absolute;
  left: 58px;
  top: 404px;
  display: flex;
  z-index: 1;
}
.full-name-text {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 13px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 204px;
  top: 297px;
  width: 141px;
  z-index: 1;
}
.full-name2 {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 13px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 58px;
  top: 380px;
  width: 141px;
  z-index: 1;
}
.username {
  width: 274px;
  height: 65px;
  position: static;
  z-index: 1;
}
.rectangle-30 {
  background: #222222;
  border-radius: 5px;
  border-style: solid;
  border-color: #353535;
  border-width: 1px;
  width: 125px;
  height: 20px;
  position: absolute;
  left: 58px;
  top: 321px;
  display: flex;
  z-index: 1;
}
.twitch-id {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 13px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 58px;
  top: 297px;
  width: 221px;
  z-index: 1;
}
.radio-buttons {
  width: 63px;
  height: 16px;
  position: static;
  z-index: 1;
}
.frame-5 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 75px;
  top: 346px;
  z-index: 1;
}
.affiliate {
  color: #f0f0ff;
  text-align: left;
  font: 500 10px/120% 'RoobertRegular', sans-serif;
  position: relative;
  top: 2px;
  z-index: 1;
}
.ellipse-5 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #353535;
  border-width: 1px;
  width: 14px;
  height: 14px;
  position: absolute;
  left: 58px;
  top: 347px;
  z-index: 1;
}
.radio-buttons2 {
  width: 61px;
  height: 16px;
  position: static;
  z-index: 1;
}
.frame-52 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 150px;
  top: 346px;
  z-index: 1;
}
.partner {
  color: #f0f0ff;
  text-align: left;
  font: 500 10px/120% 'RoobertRegular', sans-serif;
  position: relative;
  top: 2px;
  z-index: 1;
}
.ellipse-52 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #beff00;
  border-width: 1px;
  width: 14px;
  height: 14px;
  position: absolute;
  left: 133px;
  top: 348px;
  z-index: 1;
}
.ellipse-52A {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #beff00;
  border-width: 1px;
  width: 14px;
  height: 14px;
  position: absolute;
  left: 57px;
  top: 347px;
  z-index: 1;
}
.ellipse-6 {
  background: #beff00;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  position: absolute;
  left: 135px;
  top: 350px;
  z-index: 1;
}

.ellipse-6A {
  background: #beff00;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  position: absolute;
  left: 60px;
  top: 349px;
  z-index: 1;
}

.postcode {
  inset: 0;
  display: none;

  @media only screen and (max-height: 900px) {
    display: unset;
    position: relative;
    top: -366px;
    margin-left: -12px;
    width: 307px;
    height: 476px;
    z-index: 1;
  }
}
.group-5 {
  width: 271px;
  height: 104px;
  position: static;
  z-index: 1;
}
.marketing {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 10px/110% 'RoobertRegular', sans-serif;
  position: absolute;
  left: 75px;
  top: 495px;
  width: 271px;
  z-index: 1;
}
.attention {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 300 italic 7px/100% 'RoobertRegular', sans-serif;
  position: absolute;
  left: 75px;
  top: 552px;
  width: 244px;
  z-index: 1;
}
.radio-buttons3 {
  width: 122px;
  height: 12px;
  position: static;
  z-index: 1;
}
.frame-53 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 91px;
  top: 611px;
  z-index: 1;
}
.i-disagree-to-authorize {
  color: #f0f0ff;
  text-align: left;
  font: 500 10px/120% 'RoobertRegular', sans-serif;
  position: relative;
  z-index: 1;
}
.ellipse-53 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #353535;
  border-width: 1px;
  width: 12px;
  height: 12px;
  position: absolute;
  left: 75px;
  top: 611px;
}
.radio-buttons4 {
  width: 108px;
  height: 13px;
  position: static;
  z-index: 1;
}
.frame-54 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 91px;
  top: 583px;
  z-index: 1;
}
.i-agree-to-authorize {
  color: #f0f0ff;
  text-align: left;
  font: 500 10px/120% 'RoobertRegular', sans-serif;
  position: relative;
  z-index: 1;
}
.ellipse-54 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #beff00;
  border-width: 1px;
  width: 12px;
  height: 12px;
  position: absolute;
  left: 75px;
  top: 584px;
  z-index: 1;
}
.ellipse-62 {
  background: #beff00;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  position: absolute;
  left: 77px;
  top: 586px;
  z-index: 1;
}
.ellipse-63 {
  background: #beff00;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  position: absolute;
  left: 77px;
  top: 613px;
  z-index: 1;
}

.text-field {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  font: 600 13px/23.52px 'RoobertRegular', sans-serif;
  margin-left: 4px;
  margin-top: 0px;
  width: 115px;
  z-index: 100 !important;
}
</style>
