<template>
  <div class="form">
    <div class="rectangle-12"></div>
    <template v-for="(mcq, index) in multipleChoices">
      <template>
        <div class="row" :style="'top: ' + ((index + 1) * (mcq.choices === null ? 30 : -150) + (mcq.choices === null ? (index + 1) * -140 : (index + 1) * 40) + 1175 + index * 400) + 'px !important'" :key="index">
          <template v-if="mcq.choices === null">
            <div class="question" :style="mcq.question.length > 92 ? 'font-size: 23px; line-height: 23px;' : ''">{{ mcq.question }}</div>
            <div class="rectangle-open">
              <textarea class="text-field" @change="(event) => answer(mcq.question, event.target.value)" rows="4"></textarea>
            </div>
          </template>
          <template v-else>
            <div class="rectangle-2"></div>
            <div class="rectangle-3"></div>
            <div class="question" :style="mcq.question.length > 92 ? 'font-size: 23px; line-height: 23px;' : ''">{{ mcq.question }}</div>
            <div class="choice-1">{{ mcq.choices?.split('|')[0] }}</div>
            <div class="choice-2">{{ mcq.choices?.split('|')[1] }}</div>
            <div class="_1st-priority">1st Priority</div>
            <div class="_2nd-priority">2nd Priority</div>
            <template v-if="isSelected(mcq.question, mcq.choices?.split('|')[0], 0) === true">
              <div class="radio-buttons" @click="answer(mcq.question, `${mcq.choices?.split('|')[1]}|${mcq.choices?.split('|')[0]}`)">
                <div class="ellipse-99"></div>
                <div class="ellipse-66"></div>
              </div>
              <div class="radio-buttons" @click="answer(mcq.question, `${mcq.choices?.split('|')[1]}|${mcq.choices?.split('|')[0]}`)">
                <div class="ellipse-89"></div>
              </div>
            </template>
            <template v-else>
              <div class="radio-buttons" @click="answer(mcq.question, `${mcq.choices?.split('|')[0]}|${mcq.choices?.split('|')[1]}`)">
                <div class="ellipse-5"></div>
              </div>
              <template v-if="isSelected(mcq.question, mcq.choices?.split('|')[1], 0) === true">
                <div class="radio-buttons" @click="answer(mcq.question, `${mcq.choices?.split('|')[0]}|${mcq.choices?.split('|')[1]}`)">
                  <div class="ellipse-53"></div>
                  <div class="ellipse-6"></div>
                </div>
              </template>
              <template v-else>
                <div class="radio-buttons" @click="answer(mcq.question, `${mcq.choices?.split('|')[1]}|${mcq.choices?.split('|')[0]}`)">
                  <div class="ellipse-89"></div>
                </div>
              </template>
            </template>
            <template v-if="isSelected(mcq.question, mcq.choices?.split('|')[1], 0)">
              <div class="radio-buttons" @click="answer(mcq.question, `${mcq.choices?.split('|')[0]}|${mcq.choices?.split('|')[1]}`)">
                <div class="ellipse-54"></div>
                <div class="ellipse-62"></div>
              </div>
              <div class="radio-buttons" @click="answer(mcq.question, `${mcq.choices?.split('|')[0]}|${mcq.choices?.split('|')[1]}`)">
                <div class="ellipse-52"></div>
              </div>
            </template>
            <template v-else>
              <div class="radio-buttons" @click="answer(mcq.question, `${mcq.choices?.split('|')[1]}|${mcq.choices?.split('|')[0]}`)">
                <div class="ellipse-77"></div>
              </div>
              <template v-if="isSelected(mcq.question, mcq.choices?.split('|')[1], 1) === true">
                <div class="radio-buttons" @click="answer(mcq.question, `${mcq.choices?.split('|')[1]}|${mcq.choices?.split('|')[0]}`)">
                  <div class="ellipse-93"></div>
                  <div class="ellipse-96"></div>
                </div>
              </template>
              <template v-else>
                <div class="ellipse-52"></div>
              </template>
            </template>
          </template>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'SignUpFormPrizes',
  props: {
    multipleChoices: {
      type: Array,
      default() {
        return []
      }
    },
    answers: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    isSelected(question, answer, priority) {
      const found = this.answers.find((a) => a.question === question)
      const options = found?.answer?.split('|')
      return found && answer === options[priority]
    },
    answer(question, answer) {
      this.$emit('answer', { question: question, answer: answer })
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  width: 1316px;
  height: 1316px;
  z-index: 1;

  @media only screen and (max-height: 900px) {
    display: none;
  }
}
.rectangle-12 {
  background: #111111;
  border-radius: 40px;
  width: 1316px;
  height: 1428px;
  position: absolute;
  left: 302px;
  top: 925px;
}
.buttons {
  background: #beff00;
  border-radius: 326.53px;
  padding: 15px 32.8px 15px 32.8px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% + 199px);
  top: calc(50% - -1572px);
}
.tab-name {
  color: var(--black-ops, #000000);
  text-align: center;
  font: 600 34.21px/42.45px 'RoobertRegular', sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.row {
  width: 1150px;
  height: 231px;
  position: absolute;
  left: 390px;
}
.l-2-prize-please-select-and-prioritize {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 25px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 393px;
  top: 1056px;
  width: 511px;
}
.rectangle-2 {
  background: #000000;
  border-radius: 111.98px;
  width: 1150px;
  height: 80px;
}
.rectangle-3 {
  background: #000000;
  border-radius: 111.98px;
  width: 1150px;
  height: 80px;
  position: absolute;
  top: 98px;
}
.rectangle-3-disabled {
  background: #222222;
  border-radius: 111.98px;
  width: 1150px;
  height: 80px;
  position: absolute;
  top: 98px;
}
.rectangle-open {
  background: #000000;
  border-radius: 56px;
  width: 1150px;
  height: 180px;
  position: absolute;
  top: 0px;
}
.choice-1 {
  color: #c1ff0c;
  text-align: center;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 500px;
  top: -50px;
  width: 305px;
}
.choice-2 {
  color: #c1ff0c;
  text-align: center;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 814px;
  top: -50px;
  width: 305px;
}
._1st-priority {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 46px;
  top: 24px;
  width: 154px;
}
._2nd-priority {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 46px;
  top: 120px;
  width: 185px;
}
.radio-buttons {
  width: 35px;
  height: 35px;
  position: static;
}
.ellipse-5 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #353535;
  border-width: 1px;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 634px;
  top: 24px;
  cursor: pointer;
}
.ellipse-77 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #353535;
  border-width: 1px;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 634px;
  top: 120px;
  cursor: pointer;
}
.ellipse-89 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #353535;
  border-width: 1px;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 954px;
  top: 24px;
  cursor: pointer;
}
.ellipse-52 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #353535;
  border-width: 1px;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 954px;
  top: 120px;
  cursor: pointer;
}
.ellipse-53 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #beff00;
  border-width: 2px;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 954px;
  top: 24px;
  cursor: pointer;
}
.ellipse-93 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #beff00;
  border-width: 2px;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 954px;
  top: 120px;
  cursor: pointer;
}
.ellipse-96 {
  background: #beff00;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  position: absolute;
  left: 959px;
  top: 125px;
  cursor: pointer;
}
.ellipse-6 {
  background: #beff00;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  position: absolute;
  left: 959px;
  top: 29px;
  cursor: pointer;
}
.ellipse-54 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #beff00;
  border-width: 2px;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 634px;
  top: 120px;
  cursor: pointer;
}
.ellipse-62 {
  background: #beff00;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  position: absolute;
  left: 639px;
  top: 125px;
  cursor: pointer;
}
.ellipse-55 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #beff00;
  border-width: 2px;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 354px;
  top: 24px;
}
.ellipse-63 {
  background: #beff00;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  position: absolute;
  left: 639px;
  top: 125px;
}
.ellipse-99 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #beff00;
  border-width: 2px;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 634px;
  top: 24px;
  cursor: pointer;
}
._2 {
  width: 1150px;
  height: 231px;
  position: static;
}
.question {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font-weight: 600;
  font-size: 25px;
  line-height: 23.52px;
  font-family: 'RoobertRegular', sans-serif;
  position: absolute;
  left: 10px;
  top: -50px;
  width: 1100px;
}
.rectangle-22 {
  background: #000000;
  border-radius: 111.98px;
  width: 1150px;
  height: 80px;
  position: absolute;
  left: 385px;
  top: 1402px;
}
.rectangle-32 {
  background: #000000;
  border-radius: 111.98px;
  width: 1150px;
  height: 80px;
  position: absolute;
  left: 385px;
  top: 1498px;
}
.foodoanda-nt-200-x-10 {
  color: #c1ff0c;
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 904px;
  top: 1347px;
  width: 254px;
}
.fun-now-nt-500-x-4 {
  color: #c1ff0c;
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 1230px;
  top: 1347px;
  width: 218px;
}
._1st-priority2 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 432px;
  top: 1425px;
  width: 154px;
}
._2nd-priority2 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 432px;
  top: 1521px;
  width: 185px;
}
.ellipse-56 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #353535;
  border-width: 1px;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 1310px;
  top: 1521px;
}
.ellipse-57 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #beff00;
  border-width: 2px;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 1310px;
  top: 1424px;
}
.ellipse-63 {
  background: #beff00;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  position: absolute;
  left: 1315px;
  top: 1429px;
}
.ellipse-58 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #beff00;
  border-width: 2px;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 1004px;
  top: 1520px;
}
.ellipse-64 {
  background: #beff00;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  position: absolute;
  left: 1009px;
  top: 1525px;
}
._3 {
  width: 1150px;
  height: 231px;
  position: static;
}
.l-4-prize-please-select-and-prioritize {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 25px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 393px;
  top: 1638px;
  width: 511px;
}
.rectangle-23 {
  background: #000000;
  border-radius: 111.98px;
  width: 1150px;
  height: 80px;
  position: absolute;
  left: 385px;
  top: 1693px;
}
.rectangle-33 {
  background: #000000;
  border-radius: 111.98px;
  width: 1150px;
  height: 80px;
  position: absolute;
  left: 385px;
  top: 1789px;
}
.steam-card-nt-500-x-7 {
  color: #c1ff0c;
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 904px;
  top: 1638px;
  width: 254px;
}
.amazon-gift-card-usd-60-x-2 {
  color: #c1ff0c;
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 1230px;
  top: 1638px;
  width: 305px;
}
._1st-priority3 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 432px;
  top: 1716px;
  width: 154px;
}
._2nd-priority3 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 432px;
  top: 1812px;
  width: 185px;
}
.ellipse-59 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #353535;
  border-width: 1px;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 1004px;
  top: 1716px;
}
.ellipse-510 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #353535;
  border-width: 1px;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 1310px;
  top: 1812px;
}
.ellipse-511 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #beff00;
  border-width: 2px;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 1310px;
  top: 1715px;
}
.ellipse-65 {
  background: #beff00;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  position: absolute;
  left: 1315px;
  top: 1720px;
}
.ellipse-512 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #beff00;
  border-width: 2px;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 1004px;
  top: 1811px;
}
.ellipse-66 {
  background: #beff00;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  position: absolute;
  left: 639px;
  top: 29px;
  cursor: pointer;
}
._4 {
  width: 1150px;
  height: 231px;
  position: static;
}
.l-5-prize-please-select-and-prioritize {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 25px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 393px;
  top: 1929px;
  width: 511px;
}
.rectangle-24 {
  background: #000000;
  border-radius: 111.98px;
  width: 1150px;
  height: 80px;
  position: absolute;
  left: 385px;
  top: 1984px;
}
.rectangle-34 {
  background: #000000;
  border-radius: 111.98px;
  width: 1150px;
  height: 80px;
  position: absolute;
  left: 385px;
  top: 2080px;
}
.steam-card-nt-500-x-17 {
  color: #c1ff0c;
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 904px;
  top: 1929px;
  width: 281px;
}
.amazon-gift-card-usd-130-x-2 {
  color: #c1ff0c;
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 1230px;
  top: 1929px;
  width: 305px;
}
._1st-priority4 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 432px;
  top: 2007px;
  width: 154px;
}
._2nd-priority4 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 432px;
  top: 2103px;
  width: 185px;
}
.ellipse-513 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #353535;
  border-width: 1px;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 1004px;
  top: 2007px;
}
.ellipse-514 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #353535;
  border-width: 1px;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 1310px;
  top: 2103px;
}
.ellipse-515 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #beff00;
  border-width: 2px;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 1310px;
  top: 2006px;
}
.ellipse-67 {
  background: #beff00;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  position: absolute;
  left: 1315px;
  top: 2011px;
}
.ellipse-516 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #beff00;
  border-width: 2px;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 1004px;
  top: 2102px;
}
.ellipse-68 {
  background: #beff00;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  position: absolute;
  left: 1009px;
  top: 2107px;
}
.text-field {
  color: var(--color-brand-muted-smoke, #ffffff);
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;

  margin-left: 45px;
  margin-top: 25px;
  width: 1060px;
  z-index: 100 !important;
}
</style>
