<template>
  <div class="exp-ui-test" :style="screenWidth > 390 ? 'margin-left: 5px;' : 'margin-left: -12px;'">
    <div class="rectangle-12"></div>
    <template v-for="(mcq, index) in multipleChoices">
      <template>
        <div class="row" :style="'top: ' + ((index + 1) * (mcq.choices === null ? 70 : -140) + (mcq.choices === null ? (index + 1) * -268 : (index + 1) * -60) + 200 + index * 275) + 'px !important'" :key="index">
          <template v-if="mcq.choices === null">
            <div class="question" :style="mcq.question.length > 42 ? 'font-size: 8px; line-height: 8px;' : ''">{{ mcq.question }}</div>
            <div class="rectangle-open">
              <textarea type="text" class="text-field" @change="(event) => answer(mcq.question, event.target.value)"></textarea>
            </div>
          </template>
          <template v-else>
            <div class="rectangle-2"></div>
            <div class="rectangle-3"></div>
            <div class="question2" :style="mcq.question.length > 42 ? 'font-size: 8px; line-height: 8px;' : ''">{{ mcq.question }}</div>
            <div class="choice-1">{{ mcq.choices?.split('|')[0] }}</div>
            <div class="choice-2">{{ mcq.choices?.split('|')[1] }}</div>
            <div class="_1st-priority">1st Priority</div>
            <div class="_2nd-priority">2nd Priority</div>
            <template v-if="isSelected(mcq.question, mcq.choices?.split('|')[0], 0) === true">
              <div class="radio-buttons" @click="answer(mcq.question, `${mcq.choices?.split('|')[1]}|${mcq.choices?.split('|')[0]}`)">
                <div class="ellipse-99"></div>
                <div class="ellipse-66"></div>
              </div>
              <div class="radio-buttons" @click="answer(mcq.question, `${mcq.choices?.split('|')[1]}|${mcq.choices?.split('|')[0]}`)">
                <div class="ellipse-89"></div>
              </div>
            </template>
            <template v-else>
              <div class="radio-buttons" @click="answer(mcq.question, `${mcq.choices?.split('|')[0]}|${mcq.choices?.split('|')[1]}`)">
                <div class="ellipse-5"></div>
              </div>
              <template v-if="isSelected(mcq.question, mcq.choices?.split('|')[1], 0) === true">
                <div class="radio-buttons" @click="answer(mcq.question, `${mcq.choices?.split('|')[0]}|${mcq.choices?.split('|')[1]}`)">
                  <div class="ellipse-53"></div>
                  <div class="ellipse-6"></div>
                </div>
              </template>
              <template v-else>
                <div class="radio-buttons" @click="answer(mcq.question, `${mcq.choices?.split('|')[1]}|${mcq.choices?.split('|')[0]}`)">
                  <div class="ellipse-89"></div>
                </div>
              </template>
            </template>
            <template v-if="isSelected(mcq.question, mcq.choices?.split('|')[1], 0)">
              <div class="radio-buttons" @click="answer(mcq.question, `${mcq.choices?.split('|')[0]}|${mcq.choices?.split('|')[1]}`)">
                <div class="ellipse-54"></div>
                <div class="ellipse-62"></div>
              </div>
              <div class="radio-buttons" @click="answer(mcq.question, `${mcq.choices?.split('|')[0]}|${mcq.choices?.split('|')[1]}`)">
                <div class="ellipse-52"></div>
              </div>
            </template>
            <template v-else>
              <div class="radio-buttons" @click="answer(mcq.question, `${mcq.choices?.split('|')[1]}|${mcq.choices?.split('|')[0]}`)">
                <div class="ellipse-77"></div>
              </div>
              <template v-if="isSelected(mcq.question, mcq.choices?.split('|')[1], 1) === true">
                <div class="radio-buttons" @click="answer(mcq.question, `${mcq.choices?.split('|')[1]}|${mcq.choices?.split('|')[0]}`)">
                  <div class="ellipse-93"></div>
                  <div class="ellipse-96"></div>
                </div>
              </template>
              <template v-else>
                <div class="ellipse-52"></div>
              </template>
            </template>
          </template>
        </div>
      </template>
    </template>
    <!-- <div class="_1">
      <div class="l-2-prizes-please-select-and-prioritize">L2 Prizes. Please select and prioritize.</div>
      <div class="rectangle-2"></div>
      <div class="foodoanda-nt-200-x-5">Foodoanda <br />NT$200 X 5</div>
      <div class="fun-now-nt-500-x-2">FunNow <br />NT$500 X 2</div>
      <div class="rectangle-3"></div>
      <div class="_1st-priority">1st Priority</div>
      <div class="_2nd-priority">2nd Priority</div>
      <div class="radio-buttons">
        <div class="ellipse-5"></div>
      </div>
      <div class="radio-buttons">
        <div class="ellipse-52"></div>
      </div>
      <div class="radio-buttons">
        <div class="ellipse-53"></div>
        <div class="ellipse-6"></div>
      </div>
      <div class="radio-buttons">
        <div class="ellipse-54"></div>
        <div class="ellipse-62"></div>
      </div>
    </div>
    <div class="_2">
      <div class="l-3-prizes-please-select-and-prioritize">L3 Prizes. Please select and prioritize.</div>
      <div class="rectangle-22"></div>
      <div class="foodoanda-nt-200-x-10">Foodoanda <br />NT$200 X 10</div>
      <div class="fun-now-nt-500-x-4">FunNow <br />NT$500 X 4</div>
      <div class="rectangle-32"></div>
      <div class="_1st-priority2">1st Priority</div>
      <div class="_2nd-priority2">2nd Priority</div>
      <div class="radio-buttons">
        <div class="ellipse-55"></div>
      </div>
      <div class="radio-buttons">
        <div class="ellipse-56"></div>
      </div>
      <div class="radio-buttons">
        <div class="ellipse-57"></div>
        <div class="ellipse-63"></div>
      </div>
      <div class="radio-buttons">
        <div class="ellipse-58"></div>
        <div class="ellipse-64"></div>
      </div>
    </div>
    <div class="_3">
      <div class="l-4-prizes-please-select-and-prioritize">L4 Prizes. Please select and prioritize.</div>
      <div class="rectangle-23"></div>
      <div class="steam-card-nt-500-x-7">Steam Card<br />NT$500 X 7</div>
      <div class="amazon-gift-card-usd-60-x-2">Amazon Gift Card USD60 X 2</div>
      <div class="rectangle-33"></div>
      <div class="_1st-priority3">1st Priority</div>
      <div class="_2nd-priority3">2nd Priority</div>
      <div class="radio-buttons">
        <div class="ellipse-59"></div>
      </div>
      <div class="radio-buttons">
        <div class="ellipse-510"></div>
      </div>
      <div class="radio-buttons">
        <div class="ellipse-511"></div>
        <div class="ellipse-65"></div>
      </div>
      <div class="radio-buttons">
        <div class="ellipse-512"></div>
        <div class="ellipse-66"></div>
      </div>
    </div>
    <div class="_4">
      <div class="l-5-prizes-please-select-and-prioritize">L5 Prizes. Please select and prioritize.</div>
      <div class="rectangle-24"></div>
      <div class="steam-card-nt-500-x-17">Steam Card<br />NT$500 X 17</div>
      <div class="amazon-gift-card-usd-130-x-2">Amazon Gift Card USD130 X 2</div>
      <div class="rectangle-34"></div>
      <div class="_1st-priority4">1st Priority</div>
      <div class="_2nd-priority4">2nd Priority</div>
      <div class="radio-buttons">
        <div class="ellipse-513"></div>
      </div>
      <div class="radio-buttons">
        <div class="ellipse-514"></div>
      </div>
      <div class="radio-buttons">
        <div class="ellipse-515"></div>
        <div class="ellipse-67"></div>
      </div>
      <div class="radio-buttons">
        <div class="ellipse-516"></div>
        <div class="ellipse-68"></div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'SignUpFormPrizesMobile',
  props: {
    multipleChoices: {
      type: Array,
      default() {
        return []
      }
    },
    answers: {
      type: Array,
      default() {
        return []
      }
    },
    screenWidth: {
      type: Number,
      default() {
        return 0
      }
    }
  },
  methods: {
    isSelected(question, answer, priority) {
      const found = this.answers.find((a) => a.question === question)
      const options = found?.answer?.split('|')
      return found && answer === options[priority]
    },
    answer(question, answer) {
      this.$emit('answer', { question: question, answer: answer })
    }
  }
}
</script>

<style lang="scss" scoped>
.exp-ui-test {
  inset: 0;
  display: none;

  @media only screen and (max-height: 900px) {
    display: unset;
    position: relative;
    top: -66px;
    margin-left: -12px;
    width: 307px;
    height: 476px;
    z-index: 1;
  }
}
.rectangle-12 {
  background: #111111;
  border-radius: 10px;
  width: 307px;
  height: 556px;
  position: absolute;
  left: 43px;
  top: 327px;
}
.buttons {
  background: #beff00;
  border-radius: 326.53px;
  padding: 1px 10px 1px 10px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: center;
  height: 30px;
  position: absolute;
  left: 158px;
  top: 828px;
}
.row {
  width: 450px;
  height: 100px;
  position: absolute;
  left: 10px;
}
.tab-name {
  color: var(--black-ops, #000000);
  text-align: center;
  font: 600 14px/42.45px 'RoobertRegular', sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
._1 {
  width: 271px;
  height: 100px;
  position: static;
}
.question {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font-weight: 600;
  font-size: 13px;
  line-height: 23.52px;
  font-family: 'RoobertRegular', sans-serif;
  position: absolute;
  left: 65px;
  top: 375px;
  width: 270px;
}
.question2 {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font-weight: 600;
  font-size: 13px;
  line-height: 23.52px;
  font-family: 'RoobertRegular', sans-serif;
  position: absolute;
  left: 65px;
  top: 355px;
  width: 245px;
}
.rectangle-2 {
  background: #000000;
  border-radius: 111.98px;
  width: 271px;
  height: 21px;
  position: absolute;
  left: 60px;
  top: 399px;
  display: flex;
}
.foodoanda-nt-200-x-5 {
  color: #c1ff0c;
  text-align: center;
  font: 500 9px/100% 'RoobertRegular', sans-serif;
  position: absolute;
  left: 168px;
  top: 377px;
  width: 57px;
}
.fun-now-nt-500-x-2 {
  color: #c1ff0c;
  text-align: center;
  font: 500 9px/100% 'RoobertRegular', sans-serif;
  position: absolute;
  left: 242px;
  top: 376px;
  width: 58px;
  height: 19px;
}
.rectangle-3 {
  background: #000000;
  border-radius: 111.98px;
  width: 271px;
  height: 21px;
  position: absolute;
  left: 60px;
  top: 424px;
}
.rectangle-3-disabled {
  background: #222222;
  border-radius: 111.98px;
  width: 271px;
  height: 21px;
  position: absolute;
  left: 60px;
  top: 424px;
}
.rectangle-open {
  background: #000000;
  border-radius: 56px;
  width: 271px;
  height: 40px;
  position: absolute;
  left: 60px;
  top: 400px;
}
._1st-priority {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 500 9px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 72px;
  top: 405px;
  width: 82px;
  height: 9px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
._2nd-priority {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 500 9px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 72px;
  top: 430px;
  width: 75px;
  height: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.radio-buttons {
  width: 9.07px;
  height: 9.07px;
  position: static;
}
.ellipse-5 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #353535;
  border-width: 0.5px;
  width: 9.07px;
  height: 9.07px;
  position: absolute;
  left: 192px;
  top: 405.26px;
}
.ellipse-52 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #353535;
  border-width: 0.5px;
  width: 9.07px;
  height: 9.07px;
  position: absolute;
  left: 266px;
  top: 430.26px;
}
.ellipse-53 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #beff00;
  border-width: 0.5px;
  width: 9.07px;
  height: 9.07px;
  position: absolute;
  left: 266px;
  top: 405px;
}
.ellipse-6 {
  background: #beff00;
  border-radius: 50%;
  width: 6.48px;
  height: 6.48px;
  position: absolute;
  left: 267.25px;
  top: 406.25px;
}
.ellipse-54 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #beff00;
  border-width: 0.5px;
  width: 9.07px;
  height: 9.07px;
  position: absolute;
  left: 192px;
  top: 430px;
}
.ellipse-62 {
  background: #beff00;
  border-radius: 50%;
  width: 6.48px;
  height: 6.48px;
  position: absolute;
  left: 193.25px;
  top: 431.25px;
}
._2 {
  width: 271px;
  height: 100px;
  position: static;
}
.l-3-prizes-please-select-and-prioritize {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 13px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 60px;
  top: 465px;
  width: 245px;
}
.rectangle-22 {
  background: #000000;
  border-radius: 111.98px;
  width: 271px;
  height: 21px;
  position: absolute;
  left: 60px;
  top: 519px;
}
.foodoanda-nt-200-x-10 {
  color: #c1ff0c;
  text-align: center;
  font: 500 9px/100% 'RoobertRegular', sans-serif;
  position: absolute;
  left: 168px;
  top: 497px;
  width: 57px;
}
.fun-now-nt-500-x-4 {
  color: #c1ff0c;
  text-align: center;
  font: 500 9px/100% 'RoobertRegular', sans-serif;
  position: absolute;
  left: 242px;
  top: 496px;
  width: 58px;
  height: 19px;
}
.rectangle-32 {
  background: #000000;
  border-radius: 111.98px;
  width: 271px;
  height: 21px;
  position: absolute;
  left: 60px;
  top: 544px;
}
._1st-priority2 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 500 9px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 72px;
  top: 525px;
  width: 82px;
  height: 9px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
._2nd-priority2 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 500 9px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 72px;
  top: 550px;
  width: 75px;
  height: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.ellipse-55 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #353535;
  border-width: 0.5px;
  width: 9.07px;
  height: 9.07px;
  position: absolute;
  left: 192px;
  top: 525.26px;
}
.ellipse-56 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #353535;
  border-width: 0.5px;
  width: 9.07px;
  height: 9.07px;
  position: absolute;
  left: 266px;
  top: 550.26px;
}
.ellipse-57 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #beff00;
  border-width: 0.5px;
  width: 9.07px;
  height: 9.07px;
  position: absolute;
  left: 266px;
  top: 525px;
}
.ellipse-63 {
  background: #beff00;
  border-radius: 50%;
  width: 6.48px;
  height: 6.48px;
  position: absolute;
  left: 267.3px;
  top: 526.3px;
}
.ellipse-58 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #beff00;
  border-width: 0.5px;
  width: 9.07px;
  height: 9.07px;
  position: absolute;
  left: 192px;
  top: 550px;
}
.ellipse-64 {
  background: #beff00;
  border-radius: 50%;
  width: 6.48px;
  height: 6.48px;
  position: absolute;
  left: 193.3px;
  top: 551.3px;
}
._3 {
  width: 271px;
  height: 100px;
  position: static;
}
.l-4-prizes-please-select-and-prioritize {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 13px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 60px;
  top: 584px;
  width: 245px;
}
.rectangle-23 {
  background: #000000;
  border-radius: 111.98px;
  width: 271px;
  height: 21px;
  position: absolute;
  left: 60px;
  top: 638px;
}
.steam-card-nt-500-x-7 {
  color: #c1ff0c;
  text-align: center;
  font: 500 9px/100% 'RoobertRegular', sans-serif;
  position: absolute;
  left: 168px;
  top: 616px;
  width: 57px;
}
.amazon-gift-card-usd-60-x-2 {
  color: #c1ff0c;
  text-align: center;
  font: 500 9px/100% 'RoobertRegular', sans-serif;
  position: absolute;
  left: 237px;
  top: 615px;
  width: 68px;
  height: 19px;
}
.rectangle-33 {
  background: #000000;
  border-radius: 111.98px;
  width: 271px;
  height: 21px;
  position: absolute;
  left: 60px;
  top: 663px;
}
._1st-priority3 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 500 9px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 72px;
  top: 644px;
  width: 82px;
  height: 9px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
._2nd-priority3 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 500 9px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 72px;
  top: 669px;
  width: 75px;
  height: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.ellipse-59 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #353535;
  border-width: 0.5px;
  width: 9.07px;
  height: 9.07px;
  position: absolute;
  left: 192px;
  top: 644.26px;
}
.ellipse-510 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #353535;
  border-width: 0.5px;
  width: 9.07px;
  height: 9.07px;
  position: absolute;
  left: 266px;
  top: 669.26px;
}
.ellipse-511 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #beff00;
  border-width: 0.5px;
  width: 9.07px;
  height: 9.07px;
  position: absolute;
  left: 266px;
  top: 644px;
}
.ellipse-65 {
  background: #beff00;
  border-radius: 50%;
  width: 6.48px;
  height: 6.48px;
  position: absolute;
  left: 267.3px;
  top: 645.3px;
}
.ellipse-512 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #beff00;
  border-width: 0.5px;
  width: 9.07px;
  height: 9.07px;
  position: absolute;
  left: 192px;
  top: 669px;
}
.ellipse-66 {
  background: #beff00;
  border-radius: 50%;
  width: 6.48px;
  height: 6.48px;
  position: absolute;
  left: 193.25px;
  top: 406.75px;
}
._4 {
  width: 271px;
  height: 100px;
  position: static;
}
.l-5-prizes-please-select-and-prioritize {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 13px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 59px;
  top: 703px;
  width: 245px;
}
.rectangle-24 {
  background: #000000;
  border-radius: 111.98px;
  width: 271px;
  height: 21px;
  position: absolute;
  left: 59px;
  top: 757px;
}
.steam-card-nt-500-x-17 {
  color: #c1ff0c;
  text-align: center;
  font: 500 9px/100% 'RoobertRegular', sans-serif;
  position: absolute;
  left: 167px;
  top: 735px;
  width: 57px;
}
.amazon-gift-card-usd-130-x-2 {
  color: #c1ff0c;
  text-align: center;
  font: 500 9px/100% 'RoobertRegular', sans-serif;
  position: absolute;
  left: 236px;
  top: 734px;
  width: 73px;
  height: 19px;
}
.rectangle-34 {
  background: #000000;
  border-radius: 111.98px;
  width: 271px;
  height: 21px;
  position: absolute;
  left: 59px;
  top: 782px;
}
._1st-priority4 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 500 9px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 71px;
  top: 763px;
  width: 82px;
  height: 9px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
._2nd-priority4 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 500 9px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 71px;
  top: 788px;
  width: 75px;
  height: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.ellipse-513 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #353535;
  border-width: 0.5px;
  width: 9.07px;
  height: 9.07px;
  position: absolute;
  left: 191px;
  top: 763.26px;
}
.ellipse-514 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #353535;
  border-width: 0.5px;
  width: 9.07px;
  height: 9.07px;
  position: absolute;
  left: 265px;
  top: 788.26px;
}
.ellipse-515 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #beff00;
  border-width: 0.5px;
  width: 9.07px;
  height: 9.07px;
  position: absolute;
  left: 265px;
  top: 763px;
}
.ellipse-67 {
  background: #beff00;
  border-radius: 50%;
  width: 6.48px;
  height: 6.48px;
  position: absolute;
  left: 266.3px;
  top: 764.3px;
}
.ellipse-516 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #beff00;
  border-width: 0.5px;
  width: 9.07px;
  height: 9.07px;
  position: absolute;
  left: 191px;
  top: 788px;
}
.ellipse-68 {
  background: #beff00;
  border-radius: 50%;
  width: 6.48px;
  height: 6.48px;
  position: absolute;
  left: 192.3px;
  top: 789.3px;
}
.text-field {
  color: var(--color-brand-muted-smoke, #ffffff);
  font: 600 9px/12.52px 'RoobertRegular', sans-serif;

  margin-left: 10px;
  margin-top: 0px;
  width: 252px;
  height: 40px;
  z-index: 100 !important;
}
.choice-1 {
  color: #c1ff0c;
  text-align: center;
  font: 500 9px/100% 'RoobertRegular', sans-serif;
  position: absolute;
  left: 170px;
  top: 375px;
  width: 55px;
  z-index: 10;
}
.choice-2 {
  color: #c1ff0c;
  text-align: center;
  font: 500 9px/100% 'RoobertRegular', sans-serif;
  position: absolute;
  left: 245px;
  top: 375px;
  width: 55px;
}
.ellipse-99 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #beff00;
  border-width: 0.5px;
  width: 9.07px;
  height: 9.07px;
  position: absolute;
  left: 192px;
  top: 405.26px;
  cursor: pointer;
}
.ellipse-89 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #353535;
  border-width: 0.5px;
  width: 9.07px;
  height: 9.07px;
  position: absolute;
  left: 266px;
  top: 405.26px;
  cursor: pointer;
}
.ellipse-77 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #353535;
  border-width: 0.5px;
  width: 9.07px;
  height: 9.07px;
  position: absolute;
  left: 192px;
  top: 430.26px;
  z-index: 10;
  cursor: pointer;
}
.ellipse-93 {
  background: #222222;
  border-radius: 50%;
  border-style: solid;
  border-color: #beff00;
  border-width: 0.5px;
  width: 9.07px;
  height: 9.07px;
  position: absolute;
  left: 264.7px;
  top: 429px;
  cursor: pointer;
}
.ellipse-96 {
  background: #beff00;
  border-radius: 50%;
  width: 6.48px;
  height: 6.48px;
  position: absolute;
  left: 266px;
  top: 430.26px;
  cursor: pointer;
}
</style>
